import Head from '@/components/head/head.component'
import PageLayout from '@/layouts/page/page.layout'
import Link from 'next/link'
import styled from 'styled-components'

export default function Home() {
  return (
    <PageLayout categories={[]}>
      <Head
        title="Zamysli sa a nechaj sa šokovať | IVETARS"
        description="Čítaj každý týždeň informačný článok na témy: DREDY, PIERCING, VEGÁNSTVO, TELESNÉ MODIFIKÁCIE. Staň sa mojím Patrónom a odomkni si prístup k zaujímavým benefitom."
        image="https://res.cloudinary.com/coderkin/image/upload/v1680204771/ivetars-blog/meta-placeholder_zi2zjr.png"
        robots="noindex"
      ></Head>
      <Container>
        <h1>404 - Stránka nebola nájdená</h1>

        <Link href={'/'}>Vrátiť sa na hlavnú stránku</Link>
      </Container>
    </PageLayout>
  )
}

const Container = styled.div`
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    color: ${({ theme }) => theme.fonts.secondary};
  }
`
